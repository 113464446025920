var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"dit-hero-about no-gutters",style:(_vm.$vuetify.breakpoint.xs
      ? 'height:450px'
      : _vm.$vuetify.breakpoint.sm
      ? 'height:355px'
      : _vm.$vuetify.breakpoint.md
      ? 'height:475px'
      : _vm.$vuetify.breakpoint.lg
      ? 'height:670px'
      : 'height:890px'),attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","align-self":"start"}}),_c('v-col',{staticClass:"text-center dit-letter mt-xs-15",attrs:{"cols":"10","sm":"8","md":"6","lg":"5","xl":"4","align-self":"center"}},[_c('p',{staticClass:"text-h4 text-sm-h3 text-md-h3 text-lg-h2 dit-text-shadow font-weight-bold dit-letter",staticStyle:{"color":"white","letter-spacing":"0.01em !important"}},[_vm._v(" ¿Qué es "),_c('span',{staticStyle:{"color":"#009ee2"}},[_vm._v("DIT GROUP")]),_vm._v("? ")]),_c('p',{staticClass:"text-caption text-sm-body-1 text-justify dit-text-shadow white--text dit-letter pl-5 pr-5",staticStyle:{"letter-spacing":"0.01em !important"}},[_vm._v(" Somos una empresa constituida en base a una sociedad de "),_c('span',{staticClass:"font-italic",staticStyle:{"color":"#009ee2"}},[_vm._v("ingenieros calificados")]),_vm._v(" con más de "),_c('span',{staticClass:"font-italic",staticStyle:{"color":"#009ee2"}},[_vm._v("20 años de experiencia;")]),_vm._v(" con el objetivo de aplicar y diseñar procesos que permiten brindar "),_c('span',{staticClass:"font-italic",staticStyle:{"color":"#009ee2"}},[_vm._v("soluciones eficaces y profesionales.")]),_vm._v(" A través de nuestros servicios con valor agregado, buscamos convertirnos en "),_c('span',{staticClass:"font-italic",staticStyle:{"color":"#009ee2"}},[_vm._v("socios estratégicos")]),_vm._v(" de nuestros clientes para desarrollar e integrar las soluciones tecnológicas más adecuadas para su crecimiento. ")])]),_c('v-col',{staticClass:"text-center py-5",attrs:{"align-self":"end","cols":"12"}},[_c('a',{on:{"click":function($event){return _vm.scrollfade('scrolled')}}},[_c('img',{attrs:{"src":require('@/assets/images/mouse_down.svg'),"height":"55px"}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }