<template>
  <v-row
    class="dit-hero-about no-gutters"
    :style="
      $vuetify.breakpoint.xs
        ? 'height:450px'
        : $vuetify.breakpoint.sm
        ? 'height:355px'
        : $vuetify.breakpoint.md
        ? 'height:475px'
        : $vuetify.breakpoint.lg
        ? 'height:670px'
        : 'height:890px'
    "
    justify="center"
  >
    <v-col cols="12" align-self="start"></v-col>
    <v-col
      cols="10"
      sm="8"
      md="6"
      lg="5"
      xl="4"
      class="text-center dit-letter mt-xs-15"
      align-self="center"
    >
      <p
        class="text-h4 text-sm-h3 text-md-h3 text-lg-h2 dit-text-shadow font-weight-bold dit-letter"
        style="color: white; letter-spacing: 0.01em !important"
      >
        ¿Qué es <span style="color: #009ee2">DIT GROUP</span>?
      </p>
      <p
        class="text-caption text-sm-body-1 text-justify dit-text-shadow white--text dit-letter pl-5 pr-5"
        style="letter-spacing: 0.01em !important"
      >
        Somos una empresa constituida en base a una sociedad de
        <span style="color: #009ee2" class="font-italic"
          >ingenieros calificados</span
        >
        con más de
        <span style="color: #009ee2" class="font-italic"
          >20 años de experiencia;</span
        >
        con el objetivo de aplicar y diseñar procesos que permiten brindar
        <span style="color: #009ee2" class="font-italic"
          >soluciones eficaces y profesionales.</span
        >
        A través de nuestros servicios con valor agregado, buscamos convertirnos
        en
        <span style="color: #009ee2" class="font-italic"
          >socios estratégicos</span
        >
        de nuestros clientes para desarrollar e integrar las soluciones
        tecnológicas más adecuadas para su crecimiento.
      </p>
    </v-col>
    <v-col align-self="end" cols="12" class="text-center py-5"
      ><a v-on:click="scrollfade('scrolled')"
        ><img
          :src="require('@/assets/images/mouse_down.svg')"
          height="55px" /></a
    ></v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    scrollfade(url) {
      const el = document.getElementById(url);
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style>
</style>