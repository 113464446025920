<template>
  <div class="about">
    <Hero />
    <Visio_mision/>
    <Ourservices />
    <Clients />
  </div>
</template>
<script>
import Hero from "../components/about/hero.vue";
import Clients from "../components/home/clients.vue";
import Ourservices from "../components/about/ourservices.vue";
import Visio_mision from '../components/about/visio_mision.vue';

export default {
  components: { Hero, Clients, Ourservices, Visio_mision },
};
</script>
