<template>
  <v-row
    style="background-color: #e5e5e5"
    class="no-gutters pt-15 pb-15"
    justify="center"
    align="center"
    id="scrolled"
  >
    <v-col
      cols="10"
      sm="5"
      md="4"
      lg="3"
      xl="2"
      v-for="item in info"
      :key="item.title"
      class=""
    >
      <v-card class="" max-width="344" elevation="0" color="#E5E5E5">
        <v-card-text>
          <p
            class="text-h4 dit-text-accent font-weight-black text-center"
            v-html="item.title"
          ></p>
          <div
            class="dit-text-accent pa-5 ma-5 text-center"
            style="border: 3px solid #009ee2; border-radius: 30px"
          >
            {{ item.content }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" style="text-align: center">
      <v-btn
        large
        color="#009EE2"
        class="mt-10 mb-5 dit-btn white--text rounded-pill"
        to="/contacto"
      >
        Contactar
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      info: [
        {
          title: "Nuestra <span class='dit-text-primary'>misión</span>",
          content:
            " Existimos para resolver todas las problemáticas y dar respuestas eficientes de forma profesional, buscamos el desarrollo constante alineados a brindar soluciones integrales a nivel nacional e internacional.",
        },
        {
          title: "Nuestra <span class='dit-text-primary'>visión</span>",
          content:
            "Ser la mayor compañía representativa a nivel nacional y aliados comercialmente con multinacionales para ser considerados referencia internacional por la calidad de nuestros servicios.",
        },
      ],
    };
  },
};
</script>

<style>
</style>