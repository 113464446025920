<template>
  <v-row
    class="pb-10 no-gutters"
    justify="center"
    style="background-color: #013156"
  >
    <v-col cols="10">
      <p
        class="
          text-h5 text-sm-h4 text-md-h4 text-lg-h3
          dit-text-primary
          font-weight-bold
          text-center
          mt-10
          mb-10
        "
      >
        Conoce nuestros <span class="white--text">5 SERVICIOS</span>
      </p></v-col
    >
    <v-col cols="10" sm="10" md="8" class="mb-10">
      <v-row justify="center">
        <v-col
          cols="10"
          sm="6"
          md="6"
          lg="4"
          v-for="item in services"
          :key="item.path"
        >
          <v-card
            @mouseover="item.hover = true"
            @mouseleave="item.hover = false"
            :class="item.hover ? item.hover_class : item.class"
            :color="item.hover ? item.color : ''"
          >
            <a :to="item.to">
              <v-img :src="item.path">
                <v-row
                  v-if="item.hover"
                  class="no-gutters"
                  style="height: 100%; width: 100%; border-radius: 50px"
                  justify="center"
                  align="center"
                  :style="item.style_hover"
                >
                  <v-col
                    cols="8"
                    class="
                      white--text
                      text-center
                      text-caption
                      text-sm-body-1
                      text-lg-body-1
                      text-xl-h6
                    "
                    >{{ item.hover_text }}</v-col
                  >
                </v-row>
              </v-img>
            </a>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          path: require("@/assets/images/inicio/svg/ditcarrier_hover.svg"),
          hover: false,
          hover_text:
            "Brinda servicios en el rubro de las telecomunicaciones, contamos con amplias unidades de negocio pensadas a resolver las problemáticas de nuestros operadores.",
          style: "border: 3px solid #1B4864; border-radius: 50px",
          style_hover: "background-color:#1B4864 ",
          color: "#306D9A",
          class: "ditcarrier",
          hover_class: "ditcarrier_hover",
          to: "/",
        },
        {
          path: require("@/assets/images/inicio/svg/ditenterprise_hover.svg"),
          hover: false,
          hover_text:
            "El mejor aliado para las empresas que requieren servicios en telecomunicaciones en el área corporativa, ayudándolos a mejorar las comunicaciones en sus negocios e incrementar su producción.",
          style: "border: 3px solid #306D9A; border-radius: 50px",
          style_hover: "background-color:#306D9A",
          color: "#306D9A",
          class: "ditcarrier",
          hover_class: "ditcarrier_hover",
          to: "/",
        },

        {
          path: require("@/assets/images/inicio/svg/dittransporte_hover.svg"),
          hover: false,
          hover_text:
            "Con nuestra división de logística, transporte y almacenamiento queremos facilitar sus procesos con personal calificado y experimentado en el manejo de sus equipos y materiales.",
          style: "border: 3px solid #003B77; border-radius: 50px",
          style_hover: "background-color:#003B77",
          color: "#003B77",
          class: "ditcarrier",
          hover_class: "ditcarrier_hover",
          to: "/",
        },
        {
          path: require("@/assets/images/inicio/svg/ditoutsourcing_hover.svg"),
          hover: false,
          hover_text:
            "Asumimos la gestión completa de los procesos, ya que seleccionamos y administramos el personal que tu empresa necesita cuando lo necesita.",
          style: "border: 3px solid #AAAAC1; border-radius: 50px",
          style_hover: "background-color:#AAAAC1",
          color: "#AAAAC1",
          class: "ditcarrier",
          hover_class: "ditcarrier_hover",
          to: "/",
        },
        {
          path: require("@/assets/images/inicio/svg/ditmantenimiento_hover.svg"),
          hover: false,
          hover_text:
            "El área de mantenimiento está dedicada a nuestros clientes, cuenta con personal y logística para atender los eventos que se susciten en el día a día.",
          style: "border: 3px solid #009EE2; border-radius: 50px",
          style_hover: "background-color:#635F63",
          color: "#635F63",
          class: "ditcarrier",
          hover_class: "ditcarrier_hover",
          to: "/",
        },
      ],
    };
  },
};
</script>

<style lang='scss'>
.ourservices-card {
  border: 3px solid white !important;
  background-color: transparent !important;
  border-radius: 50px !important;
}
.ourservices-card_hover {
  border: 3px solid #009ee2 !important;
  border-radius: 50px !important;
}
.ditcarrier {
  @extend .ourservices-card;
}
.ditcarrier_hover {
  @extend .ourservices-card_hover;
}
.my-span {
  background-color: blue;
  color: white;
  font-weight: bold;
  margin-right: 0;
  float: right;
}
</style>